import * as React from 'react';
import {ReactElement} from 'react';
import {AppPage, LL_H_C, LL_V_L, SimpleListAdapter, ModuleFE_Thunderstorm, TS_DropDown, TS_TextArea} from '@nu-art/thunderstorm/frontend';
import {COLORS, ColorsType} from '@res/colors';
import {_keys} from '@nu-art/ts-common';
import {ICONSV4} from '@app/common/frontend/icons';


export type BaseResource_State = {
	color: keyof ColorsType
	size: number
}

export abstract class PgDev_BaseResource<_State extends BaseResource_State>
	extends AppPage<{}, _State> {

	protected constructor(props: {}) {
		super(props);
		this.state = {
			color: _keys(COLORS)[0],
			size: 25,
		} as _State;
	}

	changeColor = (color: keyof ColorsType) => {
		this.setState({color: color});
	};

	render() {
		return (
			<LL_V_L>
				<LL_H_C className="ll_h_c" style={{alignItems: 'stretch', marginBottom: '24px'}}>
					<LL_V_L className="ll_v_l" style={{marginRight: '32px'}}>
						{this.renderColorsDropDown()}
						{this.renderIconSizeSlider()}
					</LL_V_L>
					{this.renderCodeSnippet()}
				</LL_H_C>
				{this.renderItems()}
			</LL_V_L>
		);
	}

	private renderCodeSnippet() {
		const snippet = this.generateCodeSnippet();
		return (
			<>
				<TS_TextArea
					id={'snippet'}
					type={'text'} style={{fontFamily: 'monospace !important', resize: 'none', width: '400px', marginRight: '12px'}} value={snippet}
					enable={false}/>
				{ICONSV4.copy({
					style: {alignSelf: 'center'},
					onClick: async () => ModuleFE_Thunderstorm.copyToClipboard(snippet)
				})}
			</>
		);
	}

	protected abstract generateCodeSnippet(): string ;

	protected abstract renderItems(): ReactElement ;

	private renderIconSizeSlider() {
		return <LL_H_C style={{minHeight: '30px', minWidth: '222px'}}>
			<div style={{minWidth: '100px', marginRight: '16px'}}>Size ({this.state.size}):</div>
			<div style={{minWidth: '222px'}}>
				<input
					className="match_width"
					type="range"
					value={this.state.size}
					min="1" max="100"
					onChange={(e) => this.setState({size: e.target.valueAsNumber} as _State & BaseResource_State)}/>
			</div>
		</LL_H_C>;
	}

	private renderColorsDropDown() {
		const adapter = SimpleListAdapter<keyof ColorsType>(_keys(COLORS), (props) => (
			<LL_H_C>
				<div style={{
					width: '20',
					height: '20',
					borderRadius: '20',
					border: '1px solid black',
					background: COLORS[props.item](),
					margin: '4px'
				}}/>
				<div>{props.item}</div>
			</LL_H_C>));

		return <LL_H_C style={{minHeight: '30px'}}>
			<div style={{minWidth: '100px', marginRight: '16px'}}>Select a color:</div>
			<TS_DropDown<keyof ColorsType>
				adapter={adapter}
				onSelected={selected => this.setState({color: selected})}
				selected={this.state.color}/>
		</LL_H_C>;
	}
}