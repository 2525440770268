import * as React from 'react';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';
import {
	AppToolsScreen,
	ATS_Backend,
	ComponentSyncInfer,
	genericNotificationAction,
	LL_H_C,
	LL_V_L,
	ModuleFE_Toaster,
	ModuleFE_BaseApi,
	SimpleListAdapter,
	TS_BusyButton,
	TS_DropDown
} from '@nu-art/thunderstorm/frontend';
import {Minute, RuntimeModules, sortArray} from '@nu-art/ts-common';
import {ModuleFE_SyncEnvV2} from '@nu-art/thunderstorm/frontend/modules/sync-env/ModuleFE_SyncEnvV2';
import {InferProps, InferState} from '@nu-art/thunderstorm/frontend/utils/types';


type ATS_SyncCollectionToEnv_Props = {
	//
};
type ATS_SyncCollectionToEnv_State = {
	upgradableModules: ModuleFE_BaseApi<any, any>[];
	env: 'dev' | 'prod'
};
const collectionNamesToSync = ['assets', 'inventory-items', 'store-inventory', 'shipments', 'product-templates'];

export class ATS_SyncCollectionToEnv
	extends ComponentSyncInfer<ATS_SyncCollectionToEnv_Props, ATS_SyncCollectionToEnv_State> {

	static screen: AppToolsScreen = {name: `Sync To Env`, renderer: this, group: ATS_Backend};

	static defaultProps = {
		modules: () => RuntimeModules().filter(module => (module as unknown as {
			ModuleFE_BaseDB: boolean
		}).ModuleFE_BaseDB),
		pageTitle: () => this.screen.name
	};

	constructor(p: ATS_SyncCollectionToEnv_Props) {
		super(p);
		// @ts-ignore
		FirebaseAnalyticsModule.setCurrentScreen(this.pageTitle);
	}

	protected deriveStateFromProps(nextProps: InferProps<this>, state: InferState<this>) {
		state.upgradableModules ??= sortArray(RuntimeModules().filter(module => {
			const _module = module as ModuleFE_BaseApi<any, any>;
			return (!!_module.getCollectionName && collectionNamesToSync.includes(_module.getCollectionName()));
		}), i => i.getCollectionName());
		return state;
	}

	private syncToEnv = async (collectionName: string, module: ModuleFE_BaseApi<any, any>) => {
		const env = this.state.env;
		if (!env)
			return ModuleFE_Toaster.toastError('Select Env');

		await genericNotificationAction(async () => {
			const toSyncRequest = {env, moduleName: module.dbDef.dbKey, items: module.cache.allMutable()};
			await ModuleFE_SyncEnvV2.vv1.syncToEnv(toSyncRequest).setTimeout(5 * Minute)
				.executeSync();
		}, `Upgrading ${collectionName}`);
	};

	render() {
		return <LL_V_L className="collection-upgrades-page">
			<TS_DropDown selected={this.state.env} adapter={SimpleListAdapter(['dev', 'prod'], env => <>{env.item}</>)} onSelected={env => this.setState({env})}/>
			<LL_H_C className={'buttons-container'}>
				{this.state.upgradableModules.map(module => {
					const name = module.getCollectionName().replace(/-/g, ' ');
					return <TS_BusyButton
						key={name}
						onClick={() => this.syncToEnv(name, module)}
					>{name} ({module.cache.all().length})</TS_BusyButton>;
				})}
			</LL_H_C>
		</LL_V_L>;
	}
}
