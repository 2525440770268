import * as React from 'react';
import {COLORS, ColorsType} from '@res/colors';
import {_keys} from '@nu-art/ts-common';
import {BaseResource_State, PgDev_BaseResource} from './PgDev_BaseResource';
import {ATS_Frontend} from '@nu-art/thunderstorm/frontend';
import {IconsType, ICONSV4} from '@app/common/frontend/icons';


type State = BaseResource_State & {
	icon: keyof IconsType
}

class PgComponent
	extends PgDev_BaseResource<State> {
	static defaultProps = {
		pageTitle: () => PgDev_Icons.name
	};

	constructor(props: {}) {
		super(props);
	}

	protected deriveStateFromProps(nextProps: {}): State {
		return {
			...this.state, icon: _keys(ICONSV4)[0],
			color: _keys(COLORS)[0],
			size: 25
		};
	}

	protected generateCodeSnippet(): string {
		return `ICONSV4.${this.state.icon}(COLORS.${this.state.color}(), ${this.state.size},{ onClick: (e) => {}})`;
	}

	changeColor = (color: keyof ColorsType) => {
		this.setState({color: color});
	};

	protected renderItems(): React.ReactElement {
		return <div style={{overflowY: 'scroll'}}>
			{_keys(ICONSV4).map(icon => (
				<div key={icon} style={{padding: '10px', height: '80px', float: 'left'}}>{ICONSV4[icon]({onClick: () => this.setState({icon})})}</div>
			))}
		</div>;
	}
}

export const PgDev_Icons = {name: 'Icons', renderer: PgComponent, group: ATS_Frontend};
