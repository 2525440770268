export const config = {
  "label": "prod",
  "ModuleFE_Thunderstorm": {
    "appName": "manager-frontend - (prod)"
  },
  "ModuleFE_XHR": {
    "origin": "https://mng.be.petitfawn.com",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyD_zO7uY-0fIhTFR_NPv2320sl0tYRw8Ts",
      "authDomain": "shopify-manager-tool.firebaseapp.com",
      "databaseURL": "https://shopify-manager-tool-default-rtdb.firebaseio.com",
      "projectId": "shopify-manager-tool",
      "storageBucket": "shopify-manager-tool.appspot.com",
      "messagingSenderId": "527530216622",
      "appId": "1:527530216622:web:8509ced07bb75013f8f13f",
      "measurementId": "G-3E915TD8G9"
    }
  }
};