import * as React from 'react';
import {AppPage, ATS_ToRefactor} from '@nu-art/thunderstorm/frontend';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';


class Pg_Component
	extends AppPage {

	static defaultProps = {
		pageTitle: () => PgDev_Analytics.name
	};

	constructor(p: {}) {
		super(p);
		// @ts-ignore
		FirebaseAnalyticsModule.setCurrentScreen(this.pageTitle);
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {};
	}

	render() {
		return <div style={{height: '20px'}} onClick={() => {
			FirebaseAnalyticsModule.logEvent('playground', {key: 'test'});
		}}>Click me</div>;
	}
}

export const PgDev_Analytics = {name: 'Analytics', renderer: Pg_Component, group: ATS_ToRefactor};
