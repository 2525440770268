import {DB_CustomerOrder} from '@app/customer-relation/_entity/customer-order/shared';


export const DummyOrder1:DB_CustomerOrder = {
	blob: '',
	gateway: '',
	'_shippingAddressMD5': '42c91fd996187f94666542cf6b866ecc',
	'note': '',
	'syncTimestamp': 1723108002309,
	'timestampCompleted': 1723103668000,
	'orderNumber': 4882,
	'itemIds': [
		{
			'quantity': 1,
			'productId': 'f135f3659e099192f24ed30365af14f4',
			'price': 139,
			'discount': 27.8,
			'variantId': 'ed2c57656430a83bfaff031b6f0aa0e8'
		},
		{
			'quantity': 1,
			'productId': 'e5118915243e85611153492c7f0ca4d2',
			'price': 129,
			'discount': 77.4,
			'variantId': '3399e6f5489667eb273ddfc5c1ffe1e3'
		},
		{
			'quantity': 1,
			'productId': 'f648a878446ccf0965b2986c017f758a',
			'price': 139,
			'discount': 83.4,
			'variantId': '189e72fb1d5a75c3bf2385af9653eb52'
		}
	],
	'isPickup': false,
	'__created': 1723108002358,
	'shippingOptionId': '812e4df6d78e4987c92f904307cba7e2',
	'locale': 'he-IL',
	'cart': '',
	'refunds': [],
	'shippingOption': {
		'price': {
			'currency': 'ILS',
			'value': 30
		},
		'title': 'Home Delivery'
	},
	'_billingAddressMD5': 'ad59ce70361a76db8e9e490de12b8190',
	'fulfillments': [],
	'timestampCreated': 1723103667000,
	'deliveryDetails': [],
	'customerId': 'b6229b9d114b5badcccaf633c97df5c0',
	'packagingStatus': {
		'packagesSize': [],
		'isReady': false
	},
	'__updated': 1723108002358,
	'checkoutToken': '4989bd028fd03bde8e386badc9a0cbc2',
	'discountCodes': [
		{
			'amount': 54.6,
			'code': 'VACATION24',
			'type': 'percentage'
		}
	],
	'shopifyId': 5867892605186,
	'storeId': '1d834fdb6318a3f4a9764c987c424b72',
	'token': '891409f3666ac4d590b21cbe6fa9d878',
	'tags': [
		''
	],
	'_v': '1.0.2',
	'paid': {
		'currency': 'ILS',
		'value': 248.4
	},
	'messages': {},
	'shippingAddress': {
		'country': 'Israel',
		'city': 'תל אביב',
		'street': 'יונה הנביא',
		'contact': {
			'firstName': 'פלוני',
			'lastName': 'אלמוני',
			'phoneNumber': '+972 50 256 5322'
		},
		'houseNumber': 10,
		'fullFormattedAddress': 'יונה הנביא 01, תל אביב, Israel',
		'apartment': {
			'number': 46
		}
	},
	'billingAddress': {
		'country': 'Israel',
		'city': 'תל אביב',
		'street': 'יונה הנביא',
		'contact': {
			'firstName': 'פלוני',
			'lastName': 'אלמוני',
			'phoneNumber': '+972 50 256 5322'
		},
		'houseNumber': 10,
		'fullFormattedAddress': 'יונה הנביא 10, תל אביב, Israel',
		'apartment': {
			'number': 46
		}
	},
	'_id': 'b2fdf76b955c80468a1ade30b77fdb50',
	'status': [
		'order-delivery'
	]
};
